import React from "react"
import Hero from "../components/hero"
import Dotdotdot from "react-dotdotdot"
import Header from "../components/header"
import Footer from "../components/footer"
import styles from "./index.module.css"


const BioFullInfo = (props) => {
  return (
    <section className={styles.bioBlock}>
      <div>
        <div>
        <div className={styles.bioblockh2}>
            Personal Data
          </div>

          <table>
            <tbody>
            <tr>
              <td>Date of Birth:</td>
              <td>12 March 1990</td>
            </tr>
            <tr>
              <td>Date of Birth:</td>
              <td>13, Paidikis eksoxis, Patra, Greece 25002</td>
            </tr>
            <tr>
              <td>Phone:</td>
              <td><a href="tel: +30 6978620030">6978620030</a></td>
            </tr>
            <tr>
              <td>Email:</td>
              <td><a href="mailto: svpappa@gmail.com">svpappa@gmail.com</a></td>
            </tr>
            </tbody>
          </table>
        </div>
        <div>
        <div className={styles.bioblockh2}>
            Education
          </div>

          <table>
            <tbody>
            <tr>
              <td>July 2017</td>
              <td>
                <strong>Master of Science</strong> (MSc degree) in Food bio-processes and bio-refineries
                Department of Food Science and Technology and Human Nutrition
                <strong> Agriculture University of Athens</strong>, Greece
                Gpa: 9.19/10
              </td>
            </tr>
            <tr>
              <td>April 2015</td>
              <td><strong>Diploma</strong> (five-year studies B.S./M.Eng degree) in Chemical Engineering <strong>University
                of Patras</strong>, Greece
                Gpa: 7.17/10
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div>
        <div className={styles.bioblockh2}>
            Master thesis
          </div>
          <p>
            ”Electrodialysis (ED) study for the recovery of small chain carboxylic acids and mathematical mod-
            eling of the electrochemical process”. A prediction model of the concentration values based on

            the current and a dynamic model based on the electrodiffusion theory was formed based on ex-
            perimental data that we performed.

            Supervisor: <a href="mailto: akoutinas@aua.gr">Prof. Apostolos Koutinas</a>
          </p>
        </div>
        <div>

        <div className={styles.bioblockh2}>
            Diploma thesis
          </div>
          <p>
            ”Inverse problems of electroencephalography (EEG)”. This thesis deals with the analysis of the be-
            havior of the inversion algorithm in the case of the simultaneous existence of two, three or more

            dipole currents in order to detect the position of a hypothetical dipole that would create the same
            potential, taking as a pattern the entire skull modeled as a sphere of constant conductivity.
            Supervisor: <a href="mailto: gdassios@chemeng.upatras.gr">Prof. Georgios Dassios</a>
          </p>
        </div>
        <div>
        <div className={styles.bioblockh2}>
            Other Education
          </div>

          <table>
            <tbody>
            <tr>
              <td>July 2017</td>
              <td>
                <strong>
                  Summer School
                </strong>
                <br/>
                International Synthetic and Systems Biology, SSBSS, Pisa, Italy
              </td>
            </tr>
            <tr>
              <td>2015- 2016</td>
              <td>
                <strong>Certification – Viticulture & Oenology
                </strong>
                <br/>
                National and Kapodistrian University of Athens, Greece
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div>
        <div className={styles.bioblockh2}>
            Honors & Awards
          </div>
          <table>
            <tbody>
            <tr>
              <td>2016- 2017</td>
              <td>
                <strong>
                  Erasmus Scholarship
                </strong>
                <br/>
                Student Mobility for Work Placement–European Commission
              </td>
            </tr>
            <tr>
              <td>2009- 2010</td>
              <td>
                <strong>
                  Honorary Scholarship
                </strong>
                <br/>
                as a result of performance in my studies–State Scholarship Foundation
              </td>
            </tr>
            <tr>
              <td>2003- 2008</td>
              <td>
                <strong>
                  Distinction of excellence
                </strong>
                <br/>
                GPA 18.6 and above/20–Greek Ministry
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div>
        <div className={styles.bioblockh2}>
            Work Experience
          </div>
          <table className={styles.tablecv2}>
            <tbody>
            <tr>
              <td>
                <div className={styles.bioblockh3}>University College London</div>
                Advanced Centre for Biochemical Engineering<br/>
                & Centre for Process Systems Engineering<br/>
                London, United Kingdom
              </td>
              <td>
                <strong>Internship</strong> March 2017 - June 2017. We developed a basal chemi-
                cally defined media for CHO cell cultures by investigating the effects

                of nutrient groups on cell growth, cell proliferation and protein pro-
                duction during batch cultivation. Further media optimization stud-
                ies through design of experiments aimed to investigate the individual

                and cumulative effect of the nutrient groups on cell culture perfor-
                mance. We were using mammalian cell culture techniques, analyt-
                ical techniques for metabolic characterization and protein quantifi-
                cation and a flow cytometry based technique.
              </td>
            </tr>
            <tr>
              <td>
                <div className={styles.bioblockh3}>Observatory</div>
                of Road Axes in Western Greece and Peloponnese<br/>
                Patra, Greece
              </td>
              <td>
                <strong>
                  Data Consultant
                </strong> 2015 - Still Active. Processing of spatial and
                social data, in constant time base and with appropriate statistical
                methods and techniques in order to support the monitoring of the
                spatial effects of construction and operation of highways in Greece
                by providing useful and reliable information on the type and extent
                of the induced changes in the economic and social environment of
                their areas of influence.
              </td>
            </tr>
            <tr>
              <td>
                <div className={styles.bioblockh3}>Y. Cotrotsos Winery</div>
                Achaia, Greece
              </td>
              <td>
                <strong>
                  Lab Assistant
                </strong> Sept 2014. Operation/maintenance of labora-
                tory equipment and general lab cleaning/organization. Weigh-
                ing out/performing chemical/adjunct additions to juice, must, and
                wine. Benchtop juice/must/wine analyses to include temp, pH, EtOH,
                Free/Total SO2. Paper Chromatography and Enzymatic analyses to in-
                clude Malic Acid, NOPA, Glucose/Fructose
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div>
        <div className={styles.bioblockh2}>
            Academic Projects
          </div>
          <table>
            <tbody>
            <tr>
              <td>March 2016 - July 2016</td>
              <td>
                Techno-economic analysis of polyhydroxybutyrate production from crude glycerol
              </td>
            </tr>
            <tr>
              <td>March 2013 - June 2013</td>
              <td>
                Technical and Economic Study of Food Waste of NUTRY A.C. for bioethanol production.
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div>
        <div className={styles.bioblockh2}>
            Research Interests
          </div>
          <p>
            Food Science and Technology, Quality control, Biochemistry, Systems Engineering, Bio-processing,
            Environment, Applied Biotechnology, Synthetic Biology, Metabolic Engineering, Mammalian Cell
            Biotechnology.
          </p>
        </div>
      </div>
      <div>
        <div className={styles.bioblockh2}>
          Languages
        </div>
        <table>
          <tbody>
          <tr>
            <td>Greek:</td>
            <td>
              Mother-tongue
            </td>
          </tr>
          <tr>
            <td>English:</td>
            <td>
              Proficient
            </td>
          </tr>
          <tr>
            <td>French:</td>
            <td>
              Proficient
            </td>
          </tr>
          <tr>
            <td>Spanish:</td>
            <td>
              Basic Knowledge
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div>
        <div className={styles.bioblockh2}>
          Computer Skills
        </div>
        <table>
          <tbody>
          <tr>
            <td>Operating System:</td>
            <td>
              Ms Windows
            </td>
          </tr>
          <tr>
            <td>Programming Languages:</td>
            <td>
              Matlab, Fortran 90
            </td>
          </tr>
          <tr>
            <td>Mathematical Languages:</td>
            <td>
              Mathematica
            </td>
          </tr>
          <tr>
            <td>Text Editor:</td>
            <td>
              LATEX
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div>
      <div>
        <div className={styles.bioblockh2}>
          Interests and Activities
        </div>
        <p>
          Music, Tennis, Photography, Book reviews/supporting/publishing.
        </p>
      </div>
      </div>
            <div>
      <div>
        <div className={styles.bioblockh2}>
          References
        </div>
        <p>
          Available upon request
        </p>
      </div>
      </div>
    </section>
  )
}

class AboutPage extends React.Component {

  state = {
    bio: null,
  }

  onBioChange = (bio) => {
    this.setState({ bio })
  }

  onBioClose = (e) => {
    e.preventDefault()
    this.setState({
      bio: null,
    })
  }

  renderBioShort = (bio) => {
    const { name, info, title } = bio
    return (
      <div className={"team-member " + styles.team}>
        <h3 style={{ height: 80, display: "flex", alignItems: "flex-end" }}>{name}</h3>
        <span style={{ height: 20 }}>{title}</span>
        <Dotdotdot className={styles.team__info} clamp={3}>
          {info}
        </Dotdotdot>
        <a href="#"
           style={{ marginTop: "1rem" }}
           onClick={(e) => {
             e.preventDefault()
             this.onBioChange(bio)
           }}>
          Διαβάστε περισσότερα
        </a>
      </div>
    )
  }


  render() {
    const { bio } = this.state
    return (
      <div>
        <Header/>
        <Hero/>
        <section className="team-2">
          <BioFullInfo/>
        </section>
        <Footer/>
      </div>
    )
  }
}

export default AboutPage